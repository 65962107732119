.image-drag {
    position: relative;
    border: 1px dashed #828a99;
    background: #FEFEFE;
    margin: 0 auto; 
}
    .image-drag-zone,
    .image-drag .file-button {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      inset: 0; }
    .image-drag-zone svg {
      transition: transform 300ms cubic-bezier(0, 0, 0.2, 1); }
    .image-drag-zone:hover svg {
      transform: translateY(-6px); }
    .image-drag .file-button {
      opacity: 0;
      font-size: 0; }
    .image-drag .file-button-edit {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(30px, -50%);
      min-width: 50px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #bdbdbd;
      padding: 0;
      font-size: 0; }
      .image-drag .file-button-edit:hover {
        background-color: #66bb6a; }
    .image-drag.image-drag--simple {
      border-radius: 0;
      width: 100%;
      height: 355px; 
    }